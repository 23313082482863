// 抽奖页面&报告会
const routes = [
	{
		path: '/lottery',
		children: [
			// type1
			{
				path: 'bgh1/:sign',
				name: 'bgh1',
				component: () => import("@/views/lottery/type1/bgh/index.vue"),
			},
			{
				path: 'index1/:sign',
				name: 'index1',
				component: () => import("@/views/lottery/type1/index/index.vue"),
			},

			// type2
			{
				path: 'bgh2/:sign',
				name: 'bgh2',
				component: () => import("@/views/lottery/type2/bgh/index.vue"),
			},
			{
				path: 'index2/:sign',
				name: 'index2',
				component: () => import("@/views/lottery/type2/index/index.vue"),
			},

			// type3
			{
				path: 'bgh3/:sign',
				name: 'bgh3',
				component: () => import("@/views/lottery/type3/bgh/index.vue"),
			},
			{
				path: 'index3/:sign',
				name: 'index3',
				component: () => import("@/views/lottery/type3/index/index.vue"),
			},

			// type4
			{
				path: 'bgh4/:sign',
				name: 'bgh4',
				component: () => import("@/views/lottery/type4/bgh/index.vue"),
			},
			{
				path: 'index4/:sign',
				name: 'index4',
				component: () => import("@/views/lottery/type4/index/index.vue"),
			},

			// 20231212
			{
				path: '20231212',
				children: [
					{
						path: 'bgh/:sign',
						name: '20231212bgh',
						component: () => import("@/views/lottery/20231212/bgh/index.vue"),
					},
					{
						path: 'index/:sign',
						name: '20231212lottery',
						component: () => import("@/views/lottery/20231212/index/index.vue"),
					}
				]
			},

			// 20240415
			{
				path: '20240415',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'bgh',
								name: '20240415_general_bgh',
								component: () => import("@/views/lottery/20240415/bgh/index.vue"),
								meta: {
									sign: '20240415general_tea'
								}
							},
							{
								path: 'page',
								name: '20240415_general_lottery',
								component: () => import("@/views/lottery/20240415/index/index.vue"),
								meta: {
									sign: '20240415general_tea'
								}
							}
						]
					}
				]
			},
			// 20240605
			{
				path: '20240605test',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'bgh',
								name: '20240605_general_bgh',
								component: () => import("@/views/lottery/20240605test/bgh/index.vue"),
								meta: {
									sign: '20240605test'
								}
							},
							{
								path: 'page',
								name: '20240605_general_lottery',
								component: () => import("@/views/lottery/20240605test/index/index.vue"),
								meta: {
									sign: '20240605test'
								}
							}
						]
					}
				]
			},
			// 20240617_g
			{
				path: '20240617_g',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'bgh',
								name: '20240617teacher_g_bgh',
								component: () => import("@/views/lottery/20240617_g/bgh/index.vue"),
								meta: {
									sign: '20240617teacher_g'
								}
							},
							{
								path: 'page',
								name: '20240617teacher_g_lottery',
								component: () => import("@/views/lottery/20240617_g/index/index.vue"),
								meta: {
									sign: '20240617teacher_g'
								}
							}
						]
					}
				]
			},
			// 20240617_y
			{
				path: '20240617_y',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'bgh',
								name: '20240617teacher_y_bgh',
								component: () => import("@/views/lottery/20240617_y/bgh/index.vue"),
								meta: {
									sign: '20240617teacher_y'
								}
							},
							{
								path: 'page',
								name: '20240617teacher_y_lottery',
								component: () => import("@/views/lottery/20240617_y/index/index.vue"),
								meta: {
									sign: '20240617teacher_y'
								}
							}
						]
					}
				]
			},
			// 20240722_g
			{
				path: '20240722_g',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'bgh',
								name: '20240722teacher_g_bgh',
								component: () => import("@/views/lottery/20240722_g/bgh/index.vue"),
								meta: {
									sign: '20240722_g'
								}
							},
							{
								path: 'page',
								name: '20240722teacher_g_lottery',
								component: () => import("@/views/lottery/20240722_g/index/index.vue"),
								meta: {
									sign: '20240722_g'
								}
							}
						]
					}
				]
			},
			// 20240722_y
			{
				path: '20240722_y',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'bgh',
								name: '20240622teacher_y_bgh',
								component: () => import("@/views/lottery/20240722_y/bgh/index.vue"),
								meta: {
									sign: '20240722_y'
								}
							},
							{
								path: 'page',
								name: '20240622teacher_y_lottery',
								component: () => import("@/views/lottery/20240722_y/index/index.vue"),
								meta: {
									sign: '20240722_y'
								}
							}
						]
					}
				]
			},
			// 20241022_g
			{
				path: '20241022_g',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241022teacher_g_lottery',
								component: () => import("@/views/lottery/20241022_g/index/index.vue"),
								meta: {
									sign: '20_20241022gch'
								}
							}
						]
					}
				]
			},
			// 20241022_y
			{
				path: '20241022_y',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241022teacher_y_lottery',
								component: () => import("@/views/lottery/20241022_y/index/index.vue"),
								meta: {
									sign: '20_20241022ysj'
								}
							}
						]
					}
				]
			},
			// 20241104_g
			{
				path: '20241104_g',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241104teacher_g_lottery',
								component: () => import("@/views/lottery/20241104_g/index/index.vue"),
								meta: {
									sign: '20_20241104gch'
								}
							}
						]
					}
				]
			},
			// 20241104_y
			{
				path: '20241104_y',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241104teacher_y_lottery',
								component: () => import("@/views/lottery/20241104_y/index/index.vue"),
								meta: {
									sign: '20_20241104ysj'
								}
							}
						]
					}
				]
			},
			// 20241118_g
			{
				path: '20241118_g',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241118teacher_g_lottery',
								component: () => import("@/views/lottery/20241118_g/index/index.vue"),
								meta: {
									sign: '20_20241118gch'
								}
							}
						]
					}
				]
			},
			// 20241118_y
			{
				path: '20241118_y',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241118teacher_y_lottery',
								component: () => import("@/views/lottery/20241118_y/index/index.vue"),
								meta: {
									sign: '20_20241118ysj'
								}
							}
						]
					}
				]
			},
			// 20241203_g
			{
				path: '20241203_g',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241203teacher_g_lottery',
								component: () => import("@/views/lottery/20241203_g/index/index.vue"),
								meta: {
									sign: '20_20241203gch'
								}
							}
						]
					}
				]
			},
			// 20241203_y
			{
				path: '20241203_y',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241203teacher_y_lottery',
								component: () => import("@/views/lottery/20241203_y/index/index.vue"),
								meta: {
									sign: '20_20241203ysj'
								}
							}
						]
					}
				]
			},
     {
				path: '20241218_y',
				children: [
					{
						path: 'general',
						children: [
							{
								path: 'page',
								name: '20241218teacher_y_lottery',
								component: () => import("@/views/lottery/20241218test2/index/index.vue"),
								meta: {
									sign: '20_20241218test2'
								}
							}
						]
					}
				]
			},	
		]
	}
]

export default routes