const routes = [
    {
        path: "/20/zjd",
        children: [
            {
                path: "20_20241104khysj",
                component: () => import("@/views/20/zjd/20241104/index.vue"),
                meta: {
                    sign: "20_20241104khysj",
                },
            },
            {
                path: "20_20241104khgch",
                component: () => import("@/views/20/zjd/20241104/index.vue"),
                meta: {
                    sign: "20_20241104khgch",
                },
            },
            {
                path: "20_20241217test",
                component: () => import("@/views/20/zjd/20241217/index.vue"),
                meta: {
                    sign: "20_20241217test",
                },
            },
            
        ],
    },
];

export default routes;
